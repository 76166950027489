import _get from 'lodash/get'
import Linked from './../component/Linked'
import parseAddress from '@/utilities/address/parse'
import { homeownerDirectoryContact } from '@/services/HomeownerDirectoryContact/store'

export const methods = {
  determineRows: function(list) {
    if (!Array.isArray(list)) {
      return
    }

    var rows = list.map(entry => {
      const parsedAddress = parseAddress({
        address: _get(entry, 'address', null)
      })

      const displayName = _get(entry, 'displayName', '')
      const phone1 = _get(entry, 'phone1', '')
      const phone2 = _get(entry, 'phone2', '')
      let phone = ``
      if (phone1 && phone2)
        phone = `<a class="href-overflow-wrap" href="tel:${phone1}">${phone1}</a>, <a class="href-overflow-wrap" href="tel:${phone2}">${phone2}</a>`
      if (phone2 && !phone1)
        phone = `<a class="href-overflow-wrap" href="tel:${phone2}">${phone2}</a>`
      if (phone1 && !phone2)
        phone = `<a class="href-overflow-wrap" href="tel:${phone1}">${phone1}</a>`

      const emailAddress1 = _get(entry, 'emailAddress1', '')
      const emailAddress2 = _get(entry, 'emailAddress2', '')
      let email = ``
      if (emailAddress2 && emailAddress1)
        email = `<a class="href-overflow-wrap" href="mailto:${emailAddress1}">${emailAddress1}</a>, <a class="href-overflow-wrap" href="mailto:${emailAddress2}">${emailAddress2}</a>`
      if (emailAddress2 && !emailAddress1)
        email = `<a class="href-overflow-wrap" href="mailto:${emailAddress2}">${emailAddress2}</a>`
      if (emailAddress1 && !emailAddress2)
        email = `<a class="href-overflow-wrap" href="mailto:${emailAddress1}">${emailAddress1}</a>`

      return {
        displayName: displayName,
        address: parsedAddress,
        emails: {
          component: Linked,
          props: {
            text: email
          }
        },
        phones: {
          component: Linked,
          props: {
            text: phone
          }
        }
      }
    })

    this.rows = rows
  },

  async loadDirectoryContacts() {
    if (this.currentOwnerId && this.currentOwnerId > 0) {
      const params = {
        ownerID: this.currentOwnerId,
        retrieveAllForHoaShared: true
      }

      if (this.isDebug == true) console.debug('in loadDirectoryContacts()...')
      await homeownerDirectoryContact
        .dispatch('getDirectoryContactList', {
          params
        })
        .then(({ list }) => {
          this.determineRows(list)
        })
    }
  },

  reload() {
    this.loading = true

    this.rows = []
    this.loadDirectoryContacts()

    this.loading = false
  }
}
